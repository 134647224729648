<template>
  <div class="overflow-hidden ">
    <div v-if="!showError">

      <div style="height:100%" v-if="loading">
        <loader size="xxs" :loader-image="false" />
      </div>
      <div v-else class="payPreference flex flex-col gap-[24px]">
        <!-- Payslips Section -->
        <PayslipSection :payslip-settings="payslipSettings" @update="updateSetting('payslipSettings', $event)" />

        <!-- Payslip Notifications Section -->
        <PayslipNotificationSection :notification-settings="notificationSettings"
          @update="updateSetting('notificationSettings', $event)" />

        <!-- Payroll Reporting Section -->
        <PayrollReporting :report-data-headers="reportDataHeaders" @update="updateSetting('reportDataHeaders', $event)" />

        <div class="flex flex-row justify-end items-center">
          <Button class=" bg-dynamicBackBtn text-white buttons "
          :disabled="loading" @click="saveSettings">
          Save
        </Button>


        <Button class="cancel-button inline-block text-[#321C3B] rounded-md"
        :disabled="loading" @click="cancelChanges">
        Cancel
      </Button>
        </div>

      </div>

    </div>
    <div v-else>
      <error-page />
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import Loader from "@/components/Loader";
import PayslipSection from './Payslip';
import PayslipNotificationSection from './PayslipNotification';
import PayrollReporting from './PayrollReporting';

export default {
  components: {
    PayslipSection,
    PayslipNotificationSection,
    Loader,
    Button,
    PayrollReporting,
  },
  data() {
    return {
      showError: false,
      loading: false,
      payslipSettings: {},
      notificationSettings: {},
      reportDataHeaders: []
    };
  },
  methods: {
    async getPayPrefrences() {
      this.loading = true;
      try {
        await this.$_getPayPreferences().then(result => {
          const { payPreference, reportData } = result.data.data;
          this.payslipSettings = {
            generateOnApproval: payPreference?.generateOnApproval,
            generateOnPaid: payPreference?.generateOnPaid,
          };
          this.notificationSettings = {
            inAppNotification: payPreference?.inAppNotification,
            emailNotification: payPreference?.emailNotification,
          };
          this.reportDataHeaders = Array.isArray(reportData) ? reportData.map(item => ({
            fieldName: item.fieldName,
              status: item.status
            })) : Object.values(reportData).map(item => ({
              fieldName: item.fieldName,
              status: item.status
            }))
          this.loading = false
        });

      } catch (error) {
        this.$toasted.error(error, {
          duration: 5000
        });
      }
    },
    async saveSettings() {
      this.loading = true;
      const payload = {
        ...this.payslipSettings,
        ...this.notificationSettings,
        reportDataHeaders: Object.values(this.reportDataHeaders)
      };
      try {
        await this.$_savePayPreferences(payload);
        this.$toasted.success('Preferences saved successfully', { duration: 3000 });
        this.loading = false;
      } catch (error) {
        this.$toasted.error(error, {
          duration: 5000
        });
        this.loading = false
      }
    },
    cancelChanges() {
      this.getPayPrefrences();
    },
    updateSetting(settingType, newSettings) {
      if (newSettings) {
        this[settingType] = { ...this[settingType], ...newSettings };
      }
    },
  },
  mounted() {
    this.getPayPrefrences();
  },
};
</script>

<style scoped>
.payPreference {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 32px;
}



.save-button {
  width: max-content;
}

.save-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
</style>
