<template>
  <AccordionSection title="Payslip Notifications" subtitle="Set your notification preference.">
    <div class="flex flex-col mt-4 gap-3">
      <div class="option-details flex !flex-row items-center">
        <p class="flex-1">In-App</p>
        <label class="switch">
          <input
            type="checkbox"
            :checked="notificationSettings.inAppNotification"
            @change="updateNotification({ inAppNotification: $event.target.checked })"
          />
          <span class="slider round"></span>
        </label>
      </div>
      <div class="option-details flex !flex-row items-center">
        <p class="flex-1">Email</p>
        <label class="switch">
          <input
            type="checkbox"
            :checked="notificationSettings.emailNotification"
            @change="updateNotification({ emailNotification: $event.target.checked })"
          />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
  </AccordionSection>
</template>

<script>
import AccordionSection from './PayPreferenceAccordion';

export default {
  components: {
    AccordionSection,
  },
  props: {
    notificationSettings: {
      type: Object,
      required: true,
    },
  },
  methods: {
    updateNotification(newSettings) {
      // Emit the updated settings to the parent component
      this.$emit('update', { ...this.notificationSettings, ...newSettings });
    },
  },
};
</script>
