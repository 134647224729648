<template>
  <div>
    <div v-if="!showError">
      <card>
        <div class="flex justify-between items-center p-5">
          <div class="flex gap-3 cursor-pointer" @click="handleView">
            <icon
              icon-name="distribution"
              size="s"
              class-name="text-darkPurple"
            />
            <span class="text-darkPurple font-semibold text-base">
              View Gross Earnings Distribution
            </span>
          </div>

          <div class="flex gap-3">
            <Button class="bg-dynamicBackBtn text-white rounded" @click="addNewItem">
              Add Earning
            </Button>
          </div>
        </div>
        <div class="border border-dashed h-0 p-0 border-romanSilver" />
        <card-footer class="p-5" />
      </card>
      <div style="height:100%" v-if="loading">
        <loader size="xxs" :loader-image="false" />
      </div>
      <sTable
        v-else-if="EarningData.length"
        :headers="EarningsHeader"
        :items="EarningData"
        aria-label="earning table"
        class="w-full p-5 mb-4"
        :has-number="false"
        :loading="false"
      >
        <template v-slot:item="{ item }">
          <span v-if="item.payItem">
            {{ item.data.payItem.name }}
          </span>
          <span v-else-if="item.rateType">
            {{ item.data.rateType.label }}
          </span>

          <div class="flex" v-else-if="item.id">
            <Menu left top="-15" class="p-0" margin="30" >
              <template v-slot:title>
                <icon
                  icon-name="more_icon"
                  size="xsm"
                  class="-mt-1"
                  style="padding: 3px 10px; width: 36px; height: 36px;"
                />
              </template>
              <div style="width: 158px; height: 80px" class=" py-3 px-2 ">
                <div
                  class="flex py-1 px-1 h-8 cursor-pointer"
                  @click="Edit(item.data)"
                >
                  <icon
                    icon-name="edit"
                    class-name="text-blueCrayola mr-2"
                    size="xs"
                  />
                  <p class="pt-1" style="font-size: 14px;">
                    View and Edit
                  </p>
                </div>
                <div
                  class="flex px-1 py-1 more h-8 cursor-pointer"
                  @click="delModal(item.data.id)"
                >
                  <icon
                    icon-name="delete"
                    class-name="text-desire mr-2"
                    size="xs"
                  />
                  <p class="pt-1" style="font-size: 14px;">
                    Delete
                  </p>
                </div>
              </div>
            </Menu>
          </div>
        </template>
      </sTable>

      <div
        class="w-full h-auto flex flex-col"
        style="align-items: center"
        v-else
      >
        <div class="w-5/12 h-auto mt-5 flex" style="justify-content: center">
          <img src="@/assets/images/Earnings-Illustration.png" alt="" />
        </div>
        <div class="w-8/12 h-auto text-center">
          <p class=" mb-5 tracking-wide">
            An Overview of Pay items for earnings can be found here. This is
            empty now, but you can start adding pay items to populate this area.
          </p>
        </div>
      </div>
    </div>
    <div v-else>
      <error-page />
    </div>
    <gross-pay-chart :open-modal="openModal" @close="openModal = false" />
    <Modal v-if="modal">
      <Card class=" p-5 flex flex-col max-w-max">
        <div class>
          <div class="flex text-center">
            <p class="font-bold ml-4 my-5">
              Are you sure you want to delete this Pay Item?
            </p>
          </div>
          <div class="flex justify-center">
            <Button class="bg-dynamicBackBtn text-white" @click="Del">
              Yes
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
              @click="modal = false"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  </div>
</template>

<script>
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Menu from "@/components/Menu";
import Loader from "@/components/Loader";
import Button from "@/components/Button";
import Card from "@/components/Card";
import ErrorPage from "@/modules/Admin/error403";
import CardFooter from "@/components/CardFooter";
import Modal from "@/components/Modal";
import GrossPayChart from "./GrossPayChart";

export default {
  components: {
    STable,
    Menu,
    Loader,
    Button,
    CardFooter,
    ErrorPage,
    Card,
    GrossPayChart,
    Modal
  },
  data() {
    return {
      openModal: false,
      loading: true,
      editPayItems: false,
      EarningsHeader: [
        { title: "Pay Item", value: "payItem", width: 20 },
        { title: "Name in Payslip", value: "nameOnPayslip", width: 20 },
        { title: "Earning Type", value: "payType", width: 20 },
        { title: "Rate Type", value: "rateType", width: 30 },
        { title: "Rate Value", value: "rateValue", width: 10 },
        {
          title: "",
          value: "id",
          image: true,
          width: 8
        }
      ],
      EarningData: [],
      showError: false,
      itemId: "",
      modal: false
    };
  },

  methods: {
    async delModal(id) {
      try {
         await this.$handlePrivilege("paySettings", "deletePayItem");
        this.itemId = id;
        this.modal = true;
      } catch (error) {
         this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000
        });
      }
    },
    Del() {
      this.modal = false;
      this.loading = true;
      this.$_deletePayItemSettings(this.itemId)
        .then(() => {
          this.$toasted.success("Pay item deleted successfully", {
            duration: 3000
          });
          this.getEarningPayItems();
        })
        .catch(() => {
          this.$toasted.error(
            "Pay setting cannot be deleted because it is contained in an employee's pay run",
            { duration: 5000 }
          );
          this.getEarningPayItems();
        });
    },
   async Edit(data) {
      try {
        await this.$handlePrivilege("paySettings", "updatePayItemComponents");
        this.$router.push({
          name: "EditEarning",
          params: { id: data.id },
          query: {
            payType: data.payItem.name
          }
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000
        });
      }
    },
    async handleView(){
      try {
         await this.$handlePrivilege("paySettings", "viewGrossEarningsDistribution");
         this.openModal = true
      } catch (error) {
         this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000
        });
      }
    },
    getEarningPayItems() {
      this.loading = true;
      this.$_getEarningPayItems().then(result => {
        this.EarningData = result.data.paySettings;
        this.loading = false;
      });
    },
    async addNewItem() {
      try {
        await this.$handlePrivilege("paySettings", "addNewPayItem");
        this.$router.push({ name: "NewEarnings" });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000
        });
      }
    },
  },
  async mounted() {
    try {
       await this.$handlePrivilege("paySettings", "accessEarningsSettings");
       this.showError = false
       this.getEarningPayItems()
    } catch (error) {
      this.showError = true
    }
  }
};
</script>

<style></style>
