<template>
    <section class="payslips border border-[#CACACA] !p-[16px] rounded-[8px]">
      <div class="section-header" @click="toggleSection">
        <h2>{{ title }}</h2>
        <Icon
          icon-name="chevronDown"
          class="cursor-pointer chevron"
          :class="{ rotated: isOpen }"
        />
      </div>
      <div v-if="isOpen">
        <h3 class="subtitle text-[14px] font-400 border-b border-[#CACACA] pb-4">{{ subtitle }}</h3>
        <slot></slot>
      </div>
    </section>
  </template>
  <script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
      subtitle: {
        type: String,
        required: false,
        default: ''
      },
    },
    data() {
      return {
        isOpen: true, // You can control the default state
      };
    },
    methods: {
      toggleSection() {
        this.isOpen = !this.isOpen;
      },
    },
  };
  </script>

  <style scoped>


  .payslips {
      padding: 16px;
      border: 1px solid #CACACA;
      border-radius: 8px;
  }

  h2 {
      font-size: 16px;
      font-weight: 600;
  }

  .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
  }

  .subtitle {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 15px;
  }



  .warning .icon {
      margin-right: 10px;
      font-size: 18px;
  }

  .option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px solid #e5e5e5;
  }

  .option:last-of-type {
      border-bottom: none;
  }

  .option-details {
      display: flex;
      flex-direction: row;
  }

  .option-details p {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
  }

  .info-link {
      font-size: 12px;
      color: #007bff;
      text-decoration: none;
  }

  .info-link:hover {
      text-decoration: underline;
  }

  /* Toggle switch */
  .switch {
      position: relative;
      display: inline-block;
      width: 24px;
      height: 12px;
  }

  .switch input {
      opacity: 0;
      width: 0;
      height: 0;
  }

  .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #EEEEEE;
      transition: 0.4s;
      border-radius: 20px;
  }

  .slider:before {
      position: absolute;
      content: "";
      height: 9.6px;
      width: 9.6px;
      border-radius: 50%;
      left: 2px;
      bottom: 1.5px;
      background-color: #878E99;
      transition: 0.4s;
  }



  input:checked+.slider:before {
      transform: translateX(10px);
      background-color: #13B56A;

  }

  .slider.round {
      border-radius: 34px;
  }

  .slider.round:before {
      border-radius: 50%;
  }

  /* Chevron icon */
  .chevron {
      font-size: 14px;
      transition: transform 0.3s ease;
  }

  .chevron.rotated {
      transform: rotate(180deg);
  }
  </style>
