<template>
  <AccordionSection title="Payroll Reporting" subtitle="Select your display preferences.">
    <div class="warning mt-4 border border-[#E993235C] bg-[#E9932314]">
      <Icon icon-name="warning" size="xs" class=" mr-2" style="fill: transparent;" />

      <p>Data view for the "Paid Register" and "Payslips" are fixed and cannot be modified. However, you can modify your
        data export requirement to suit your reporting needs.</p>
    </div>

    <!-- Table Header -->
    <div class="reporting-table border border-[#00000029] rounded-md">
      <div class="header grid grid-cols-4">
        <p class="text-[12px] uppercase font-[900] !text-jet">Select to add</p>

        <p class="switch-header">Paid Register</p>
        <p class="switch-header">Payslips</p>
        <p class="switch-header">Report Data</p>

      </div>


      <div v-for="(category, index) in categorizedReportData" :key="index" class="">
        <div class="flex items-center p-3">

          <div class="grid grid-cols-4 w-full">
            <div class="flex flex-row gap-2">
              <p>{{ category?.fieldName }}</p>

            </div>
            <label class="switch">
              <input type="checkbox" :checked="category.paidRegister === 'active'" @change="toggleStatus(index)"
                disabled class=" disable:opacity-50 disabled:cursor-not-allowed" />
              <span class="slider round  opacity-50 cursor-not-allowed"></span>
            </label>
            <label class="switch">
              <input type="checkbox" :checked="category.paySlip === 'active'" @change="toggleStatus(index)" disabled />
              <span class="slider round  opacity-50 !cursor-not-allowed"></span>
            </label>
            <div class="flex">
              <label class="switch ">
                <input type="checkbox" :checked="getHeaderStatus(category.fieldName) === 'active' ? true : false"
                  @change="toggleStatus(index)" />
                <span class="slider round"></span>

              </label>
              <div class="ml-auto" v-if="category.subItems" @click="toggleCategory(index)">
                <Icon icon-name="chevronDown" class="cursor-pointer chevron col-span-[30px] ml-auto"
                  :class="{ rotated: category.isExpanded }" />
              </div>
            </div>
          </div>
        </div>
        <!-- Subcategories (only show if expanded) -->
        <div v-if="category.isExpanded" class="border-b pb-3"
          :class="{ 'border-b pb-3': category.fieldName == 'Net Pay' }">
          <div v-if="category.isExpanded" class="pl-6 " style="background-color: #F8F8F8; border-radius: 6px">
            <div v-for="(subItem, subIndex) in category.subItems" :key="`${category.fieldName}-${subIndex}`"
              class="grid grid-cols-4 py-2">
              <div class="flex !w-[100px] items-center capitalize">
                {{ subItem.id ? subItem.id : subItem?.fieldName }}
                <small v-if="subItem.ytd" class="text-[#878E99] text-[10px] ml-2">(YTD)</small>
                <!-- Tooltip: only shows if tooltip is true -->
                <div @mouseenter="showToolTip(subIndex)" @mouseleave="hideToolTip(subIndex)" class="relative ml-2"
                  v-if="subItem.id === 'Others'">
                  <Icon icon-name="icon-info" class="cursor-pointer" size="xs" />

                  <ToolTip v-if="activeTooltip === subIndex" style="width: 250px !important" left="-70px">
                    <p class="w-full mx-auto text-xs text-white text-center">
                      {{ subItem?.tooltipContent }}
                    </p>
                  </ToolTip>
                </div>
              </div>


              <label class="switch -ml-4">
                <input type="checkbox" :checked="subItem.paidRegister === 'active'" disabled
                  @change="toggleSubStatus(index, subIndex)" />
                <span class="slider round  opacity-50 !cursor-not-allowed"></span>
              </label>
              <label class="switch -ml-4">
                <input type="checkbox" :checked="subItem.paySlip === 'active'" disabled
                  @change="toggleSubStatus(index, subIndex)" />
                <span class="slider round  opacity-50 !cursor-not-allowed"></span>
              </label>
              <label class="switch -ml-4">
                <input type="checkbox"
                  :checked="category.reportData === 'inactive' ? false : getHeaderStatus(subItem.fieldName) === 'active' ? true : false"
                  @change="toggleSubStatus(index, subIndex)"
                  :disabled="category.reportData !== 'active' || category.reportData === 'inactive'" />
                <span class="slider round"></span>
              </label>

            </div>
          </div>
        </div>
      </div>
    </div>

  </AccordionSection>
</template>

<script>
import AccordionSection from './PayPreferenceAccordion';

export default {
  components: {
    AccordionSection,
    ToolTip: () => import("../../../../../components/ToolTip"),
  },
  props: {
    reportDataHeaders: {
      type: [Array, Object],
      required: true,
      default: () => []
    },
  },
  data() {
    return {
      activeTooltip: null,
      loading: null,
      EarningData: [],
      AdditionalData: [],
      DeductionData: [],
      categorizedReportData: [
        { fieldName: "Name & Designation", reportData: 'inactive', selected: false, paidRegister: 'active', paySlip: 'active' },
        { fieldName: "Employee ID", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
        { fieldName: "Employment Date", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
        { fieldName: "Length of Stay", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'inactive' },
        { fieldName: "Job Category", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'inactive' },
        { fieldName: "Location (Tax Authority)", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'inactive' },
        { fieldName: "Tax Identification Number", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
        {
          fieldName: "Regular Earnings",
          reportData: 'inactive',
          selected: false,
          isExpanded: false,
          paidRegister: 'active', paySlip: 'active',
          subItems: [
            { fieldName: "Basic Salary", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Bonus earnings", id:"Bonus", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Communication Allowance", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Daily Allowance", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Dressing Allowance", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Education Allowance", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Emergency Relief", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Entertainment Allowance", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Furniture Allowance", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Housing Allowance", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Lunch Allowance", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Medical Allowance", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Monthly Reimbursement", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Personal Development", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Professional Membership Allowance", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Publication Allowance", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Remote Work Allowance", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Research Allowance", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Subscription Allowance", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Training Allowance", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Transport Allowance", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Others Regular Earnings", id: 'Others', tooltipContent: 'For regular earnings added on the fly', reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
          ]

        },
        {
          fieldName: "Additions",
          reportData: 'inactive',
          selected: false,
          isExpanded: false,
          paidRegister: 'active', paySlip: 'active',
          subItems: [
            { fieldName: "commission", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "reimbursement", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "bonus additions", id:"Bonus", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Others Addition", id: 'Others', tooltipContent: 'For additions added on the fly', reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Advance Additions", id:'Advance', reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "loan Additions", id:'loan', reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },

          ],

        },
        {
          fieldName: "Deductions",
          reportData: 'inactive',
          selected: false,
          isExpanded: false,
          paidRegister: 'active', paySlip: 'active',
          subItems: [
            { fieldName: "Penalties", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Others Deductions", id: 'Others', tooltipContent: 'For deductions added on the fly', reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "Advance Deductions", id:'Advance', reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
            { fieldName: "loan Deductions", id:'loan', reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },

          ]

        },
        {
          fieldName: "Payee",
          reportData: 'inactive',
          selected: false,
          isExpanded: false,
          paidRegister: 'active', paySlip: 'active',
          subItems: [
            { id: "PAYE", fieldName:'Payee Ytd', ytd: true, reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
          ]
        },
        {
          fieldName: "Pension",
          reportData: 'inactive',
          selected: false,
          isExpanded: false,
          paidRegister: 'active', paySlip: 'active',
          subItems: [
            { id: "Pension", fieldName:'Pension Ytd', ytd: true, reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
          ]
        },
        {
          fieldName: "NHF",
          reportData: 'inactive',
          selected: false,
          isExpanded: false,
          subItems: [
            { id: "NHF", ytd: true, fieldName:'NHF Ytd', reportData: 'inactive', selected: false }
          ]
        },

        {
          fieldName: "Gross Pay",
          reportData: 'inactive',
          selected: false,
          isExpanded: false,
          subItems: [
            { id: "Gross Pay", fieldName:'Gross Pay Ytd', ytd: true, reportData: 'inactive', selected: false },
          ]
        },

        {
          fieldName: "Net Pay",
          reportData: 'inactive',
          selected: false,
          isExpanded: false,
          paidRegister: 'active', paySlip: 'inactive',
          subItems: [
            { id: "Net Pay", fieldName:'Net Pay Ytd', ytd: true, reportData: 'inactive', selected: false, paidRegister: 'active', paySlip: 'inactive' },
          ]
        },
        { fieldName: "Created", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'inactive' },
        { fieldName: "Approved", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'inactive' },
        { fieldName: "Paid ", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'inactive' },
        { fieldName: "Payrun Type", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'inactive' },
        { fieldName: "Pay Frequency", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
        { fieldName: "Pay Period", reportData: 'inactive', selected: false, paidRegister: 'inactive', paySlip: 'active' },
        { fieldName: "Status", reportData: 'inactive', selected: false, paidRegister: 'active', paySlip: 'inactive' },

      ]
    }
  },
  // PayrollReporting.vue
  methods: {
    syncReportHeaders() {
      if (!this.reportDataHeaders ||
        (Array.isArray(this.reportDataHeaders) && this.reportDataHeaders.length === 0) ||
        (typeof this.reportDataHeaders === 'object' && Object.keys(this.reportDataHeaders).length === 0)) {
        this.categorizedReportData.forEach(category => {
          this.addHeader({
            fieldName: category.fieldName,
            status: category.reportData
          });

          if (category.subItems) {
            category.subItems.forEach(subItem => {
              this.addHeader({
                fieldName: subItem.fieldName || subItem.id,
                status: subItem.reportData
              });
            });
          }
        });
      }

      this.categorizedReportData = this.categorizedReportData.map(category => {
        const headerStatus = this.getHeaderStatus(category.fieldName);

        const updatedCategory = {
          ...category,
          reportData: headerStatus === 'inactive' ? category.reportData : headerStatus,
        };

        if (updatedCategory.subItems) {
          updatedCategory.subItems = updatedCategory.subItems.map(subItem => {
            const subHeaderStatus = this.getHeaderStatus(subItem.fieldName);

            return {
              ...subItem,
              reportData: subHeaderStatus || subItem.reportData
            };
          });
        }

        return updatedCategory;
      });

      this.$emit('update', this.reportDataHeaders);
    },



    toggleStatus(index) {
      const category = this.categorizedReportData[index];
      category.reportData = category.reportData === 'active' ? 'inactive' : 'active';
      // Update header status based on the category
      this.updateHeaderStatus(category.fieldName, category.reportData);
      if (category.subItems) {
        // Map through the subItems to create new updated objects
        category.subItems = category.subItems.map(subItem =>
        ({
          ...subItem,
          reportData: category.reportData,
        })
        );
        // Update header status for each subItem
        category.subItems.forEach(subItem => {
          this.updateHeaderStatus(subItem.fieldName, subItem.reportData);
        });
      }
      // Emit the update event
      this.$emit('update', this.reportDataHeaders);
    },

    toggleSubStatus(categoryIndex, subIndex) {
      const subItem = this.categorizedReportData[categoryIndex].subItems[subIndex];
      subItem.reportData = subItem.reportData === 'active' ? 'inactive' : 'active';
      this.updateHeaderStatus(subItem.fieldName, subItem.reportData);
      this.$emit('update', this.reportDataHeaders);
    },

    findHeader(fieldName) {
      if (Array.isArray(this.reportDataHeaders)) {
        return this.reportDataHeaders.find(item => item.fieldName === fieldName);
      } if (typeof this.reportDataHeaders === 'object') {
        return Object.values(this.reportDataHeaders)?.find(item => item.fieldName === fieldName);
      }
      return null;
    },

    updateHeaderStatus(fieldName, status) {
      const header = this.findHeader(fieldName);
      if (header) {
        header.status = status;
      } else {
        this.addHeader({ fieldName, status });
      }
    },

    showToolTip(index) {
      this.activeTooltip = index;
    },
    hideToolTip() {
      this.activeTooltip = null;
    },

    getHeaderStatus(fieldName) {
      let header;
      if (Array.isArray(this.reportDataHeaders)) {
        header = this.reportDataHeaders.find(item => item.fieldName === fieldName);
      } else if (typeof this.reportDataHeaders === 'object') {
        header = Object.values(this.reportDataHeaders).find(item => item.fieldName === fieldName);
      }
      return header ? header.status : 'inactive';
    },

    addHeader(newHeader) {
      if (Array.isArray(this.reportDataHeaders)) {
        this.reportDataHeaders.push(newHeader);
      } else {
        this.$set(this.reportDataHeaders, newHeader.fieldName, newHeader);
      }
    },

    toggleCategory(index) {
      this.categorizedReportData[index].isExpanded = !this.categorizedReportData[index].isExpanded;
    },
    toggleCategorySelection(index) {
      this.categorizedReportData[index].selected = !this.categorizedReportData[index].selected;
    },

    toggleSubItemSelection(categoryIndex, subIndex) {
      const subItem = this.categorizedReportData[categoryIndex].subItems[subIndex];
      subItem.selected = !subItem.selected;
    },

  },
  mounted() {
    this.syncReportHeaders();
  },

};
</script>

<style scoped>
.warning {
  display: flex;
  align-items: center;
  background-color: #e9932314;
  padding: 10px;
  border-radius: 8px;
  font-size: 12px;
  color: #333333;
  border: 1px solid #e993235c;
  margin-bottom: 20px;
}

.reporting-table {
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
}

.header {
  font-weight: bold;
  border-bottom: 2px solid #ddd;
  padding-bottom: 8px;
  margin-bottom: 12px;
}

.option {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 12px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #EEEEEE;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 9.6px;
  width: 9.6px;
  border-radius: 50%;
  left: 2px;
  bottom: 1.5px;
  background-color: #878E99;
  transition: 0.4s;
}

input:checked+.slider:before {
  transform: translateX(10px);
  background-color: #13B56A;
}

.switch-header {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  color: #878E99;
}
</style>
