<template>
  <AccordionSection title="Payslips" subtitle="Set your preference for generating payslips.">
    <div class="flex flex-col mt-4 gap-3">
      <div class="warning mt-2 border border-[#E993235C] bg-[#E9932314]">
        <Icon icon-name="warning" size="xs" class=" mr-2" style="fill: transparent;"/>
        <p>Note: Payslips generated on approval will be deleted if the payroll process is canceled. If both options are selected, payslips generated on approval shall be overwritten with payslips for the paid salary.</p>
      </div>

      <div class="option-details grid grid-cols-3 items-center">
        <p class="flex-1">Generate on Payroll Approval</p>
        <p class="flex-1 text-blueCrayola">Payslip is generated once approved</p>
        <label class="switch ml-auto">
          <input
            type="checkbox"
            :checked="payslipSettings.generateOnApproval"
            @change="updateSettings({ generateOnApproval: $event.target.checked })"
          />
          <span class="slider round"></span>
        </label>
      </div>


      <div class="option-details grid grid-cols-3 items-center">
        <p class="flex-1">Generate when Payroll process is completed (paid)</p>
        <p class="flex-1 text-blueCrayola">Payslip is generated once payment is completed</p>
        <label class="switch ml-auto">
          <input
            type="checkbox"
            :checked="payslipSettings.generateOnPaid"
            @change="updateSettings({ generateOnPaid: $event.target.checked })"
          />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
  </AccordionSection>
</template>

<script>
import AccordionSection from './PayPreferenceAccordion';

export default {
  components: {
    AccordionSection,
  },
  props: {
    payslipSettings: {
      type: Object,
      required: true,
    },
  },
  methods: {
    updateSettings(newSettings) {
      // Emit the updated settings to the parent component
      this.$emit('update', { ...this.payslipSettings, ...newSettings });
    },
  },
};
</script>

<style scoped>
.warning {
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  background-color: #E9932314;
  padding: 10px;
  border-radius: 8px;
  font-size: 12px;
  color: #333333;
  border: 1px solid #E993235C;
  margin-bottom: 20px;
}
</style>
